
import ItemSlot from "@/components/community/ItemSlot.vue";
import { CharacterItems } from "@/data/characters";
import { computed, defineComponent, ref } from "vue";
import axios from "axios";

interface Props {
  items: CharacterItems;
}

interface ComponentReg {
  id: number;
  component_id: number;
  component_type: number;
}

interface RenderComp {
  icon_asset: string;
}

const EXPLORER_BASE = "https://explorer.lu/";
const LU_RES_BASE = "https://explorer.lu/lu-res/";

export default defineComponent({
  components: { ItemSlot },
  props: ["items"],
  setup(props: Props) {
    const items = computed(() => props.items);

    const api_client = axios.create({
      baseURL: EXPLORER_BASE + "api/",
      timeout: 3000,
      headers: {
        common: {
          Authorization: 'Basic bHU6ZXhwbG9yZXI='
        }
      }
    });

    const data = computed(() => {
      const { faction, ...rest } = items.value;
      const v = Object.fromEntries(
        Object.entries(rest).map((e: [string, number]) => {
          const k = e[0];
          const v = e[1];
          const icon = ref();
          const name = ref();

          api_client.get(`v0/locale/Objects/${v}/name`).then((reply) => {
            name.value = reply.data.value;
            //console.log("name:", name.value);
          });

          api_client.get(`v0/tables/ComponentsRegistry/${v}`).then((d) => {
            const comp: ComponentReg[] = d.data;
            comp.forEach(x => {
              if (x.component_type == 2) {// render
                api_client.get(`v0/tables/RenderComponent/${x.component_id}`).then((reply) => {
                  const render: RenderComp[] = reply.data;
                  render.forEach(r => {
                    const base = LU_RES_BASE + 'textures/ui/';
                    const path = base + r.icon_asset.toLowerCase()
                      .replace(/\\/g, '/')
                      .replace(/.dds$/, '.png');
                    const path2 = path
                      .replace(/\/ui\/\.\./, '')
                      .replace(/\/textures\/\.\./, '');
                    icon.value = path2;
                    //console.log("icon: ", encodeURI(icon.value));
                  })
                })
              }
            });
          });
          return [k, {id: v, icon, name}];
        })
      );
      return v;
    });

    return { items, data };
  },
});
