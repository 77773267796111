
import { computed, defineComponent } from "vue";
import { getChar } from "@/data/universe";
import { useRoute } from "vue-router";
import NexusHQBeta from "@/components/modules/NexusHQBeta.vue";
import PaperDoll from "@/components/modules/PaperDoll.vue";

export default defineComponent({
  components: {
    "nexus-hq-beta": NexusHQBeta,
    PaperDoll,
  },
  setup() {
    const route = useRoute();
    const universe = computed(() => Number(route.params["universe"]));
    const key = computed(() => String(route.params["user"]));
    const char = computed(() => getChar(universe.value, key.value));
    return { universe, key, char };
  },
});
