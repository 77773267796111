<template>
  <div class="slot hideDummy">
    <!-- /universegamecontent/item_150_7364_7863ef8eee7665d241d161da045ffab5.png -->
    <a :href="'https://explorer.lu/objects/' + data.id" target="_blank">
      <img
        class="item itemImage"
        :src="data.icon.value + '?apiKey=9c27e53c329d51c9e34650e4582a1afa5e8a4f29776369ad'"
        :alt="data.name.value"
        :title="data.id + ': ' + data.name.value"
    /></a>
  </div>
</template>

<style lang="scss" scoped>
    .itemImage {
        width: 64px;
    }
</style>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: ["data"],
  setup() {},
});
</script>
