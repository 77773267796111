<template>
  <div>
    <nexus-hq-beta></nexus-hq-beta>
    <text-module>
      <div class="negative-row">
        <div id="Header" class="column-round opaque round-heading">
          <b><i></i></b><i><b></b></i><em></em>
          <div class="column-round-body">
            <h1>Character</h1>
            <p class="back">
              <router-link class="linkArrow" to="/nexus-hq/home/index"
                >Back to search</router-link
              >
            </p>
          </div>
        </div>
        <div id="Details">
          <div id="Avatar"></div>
          <div id="Name">
            <h3>{{ char.name }}</h3>
          </div>
          <div class="stat health">
            <p class="text">Health</p>
            <p class="value">{{ char.stats ? char.stats.health : "?" }}</p>
          </div>
          <div class="stat imagination">
            <p class="text">Imagination</p>
            <p class="value">{{ char.stats ? char.stats.imagination : "?" }}</p>
          </div>
          <div class="stat armor">
            <p class="text">Armor</p>
            <p class="value">{{ char.stats ? char.stats.armor : "?" }}</p>
          </div>
        </div>
      </div>
    </text-module>
    <paper-doll v-if="char.items" :items="char.items"></paper-doll>
  </div>
</template>

<style lang="scss" scoped>
#Header .back {
  position: absolute;
  top: 10px;
  right: 20px;
}

#Details {
  position: relative;
  margin-top: 10px;
}

#Avatar {
  width: 100px;
  height: 105px;
  background-image: url(../../../assets/nexus-hq/character_sprite.png);
  background-position: -565px -346px;
}

#Name {
  position: absolute;
  left: 110px;
  top: 0px;
  width: 565px;
  height: 45px;
  background-image: url(../../../assets/nexus-hq/character_sprite.png);
  background-position: 0px -346px;

  h3 {
    position: absolute;
    top: 15px;
    left: 15px;
  }
}

.stat {
  position: absolute;
  top: 52px;
  width: 178px;
  height: 54px;
  background-image: url(../../../assets/nexus-hq/character_sprite.png);

  &.health {
    left: 110px;
    background-position: 0px -391px;
  }

  &.imagination {
    left: 303px;
    background-position: -178px -391px;
  }

  &.armor {
    left: 496px;
    background-position: -356px -391px;
  }

  .text {
    position: absolute;
    top: 32px;
    width: 100px;
    color: #fff;
    text-align: center;
  }

  .value {
    position: absolute;
    width: 80px;
    text-align: center;
    top: 18px;
    right: 5px;
    font-size: 20px;
    font-weight: bold;
    color: #fff;
  }
}
</style>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { getChar } from "@/data/universe";
import { useRoute } from "vue-router";
import NexusHQBeta from "@/components/modules/NexusHQBeta.vue";
import PaperDoll from "@/components/modules/PaperDoll.vue";

export default defineComponent({
  components: {
    "nexus-hq-beta": NexusHQBeta,
    PaperDoll,
  },
  setup() {
    const route = useRoute();
    const universe = computed(() => Number(route.params["universe"]));
    const key = computed(() => String(route.params["user"]));
    const char = computed(() => getChar(universe.value, key.value));
    return { universe, key, char };
  },
});
</script>
